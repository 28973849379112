import React from "react";
import { Link, graphql } from "gatsby";
import Helmet from "react-helmet";
import Content, { HTMLContent } from "../components/Content";
import Page from "../components/page";
import Section from "../components/section";
import FullWidthImage from "../components/FullWidthImage";
import { Title, SubTitle } from "../components/text";

export const TermsTemplate = ({
  title,
  subtitle,
  image,
  posts,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content;
  console.warn(content);
  return (
    <Page>
      <Helmet title="Wesmans | Career" />
      <FullWidthImage image={image} height="40vh" parallax={40} dim={0.4}>
        <Title bright>{title}</Title>
        <SubTitle bright marginTop>
          {subtitle}
        </SubTitle>
      </FullWidthImage>
      <Section>
        <PageContent className="content" content={content} />
      </Section>
    </Page>
  );
};

export default ({ data }) => {
  console.log(data);
  const { markdownRemark: post } = data;

  return (
    <TermsTemplate
      contentComponent={HTMLContent}
      title={post.frontmatter.title}
      subtitle={post.frontmatter.subtitle}
      image={post.frontmatter.image}
      content={post.html}
    />
  );
};

export const termsQuery = graphql`
  query Terms($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 64) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
